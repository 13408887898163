$cookie-banner-background-color: #f1f1f1 !default;
$cookie-banner-text-color: #222222 !default;
$cookie-banner-cta-base: #0075b4 !default;
$cookie-banner-cta-hover: #075683 !default;

.edx-cookie-banner-wrapper {
  background: $cookie-banner-background-color;
  box-sizing: border-box;

  /** Base Styles - start **/
  text-align: left;
  line-height: 1.5;
  font: {
    family: 'Open Sans', "Helvetica Neue", Helvetica, Arial, sans-serif;
    size: 1rem;
    weight: 400;
  }

  .alert {
    position: relative;
    padding: 0.95rem 1.35rem;
  }

  .alert-dismissible {
    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.75rem 1.25rem;
      background: transparent;
      border: 0;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
      float: right;
      line-height: 1;
      font: {
        size: 1.85rem;
        weight: 700;
      }
    }

    .btn {
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
    }
  }
  /** Base Styles - end **/


  .edx-cookie-banner {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    min-width: 0;
    margin: 0 auto;
    background: inherit;
    border: none;

    .policy-link {
      color: $cookie-banner-cta-base;
      text-decoration: underline;

      &:focus,
      &:hover {
        color: $cookie-banner-cta-hover;
        border: none;
      }
    }

    .alert-dialog {
      margin-right: 30px;
      color: $cookie-banner-text-color;
    }

    .btn.close {
      color: $cookie-banner-cta-base;

      &:focus,
      &:hover {
        color: $cookie-banner-cta-hover;
        cursor: pointer;
      }
    }
  }
}
