//
// work.scss
//
.work-container {
    overflow: hidden;
    transition: all 0.2s ease;
    .content {
        position: absolute;
        z-index: 1;
        bottom: 5%;
        left: 5%;
        .title {
            font-size: 20px;
            transition: all 0.5s ease;
        }
    }
    .content,
    .client {
        opacity: 0;
        transition: all 0.5s ease;
    }
    .client, .read_more {
        position: absolute;
        z-index: 1;
        right: 5%;
        top: 5%;
    }
    .read_more {
        height: 30px;
        width: 30px;
        line-height: 30px;
    }
    &:hover {
        .personal-port {
            opacity: 1;
        }
        .content,
        .client {
            opacity: 1;
        }
    }
}

.work-details {
    ul {
        li {
            b {
                max-width: 90px;
                width: 100%;
                display: inline-block;
            }
        }
    }
}
.work-container, .courses-desc {
    img {
        transition: all 0.5s ease;
    }
    .overlay-work {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0; 
        transition: all 0.5s ease;
        background-color: $dark;
        z-index: 1;
    }
    &:hover {
        img {
            transform: scale(1.1) rotate(3deg);
        }
        .overlay-work {
            opacity: 0.6;
        }
    }
}
//Event (index-event.html)
.event-schedule {
    transition: all 0.5s ease;
    .date {
        .day {
            font-size: 18px;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background: rgba($primary, 0.1);
            line-height: 48px;
            border: 2px solid $white;
            box-shadow: 0px 0px 2px 0.25px rgba($primary, 0.5);
        }
        .month {
            font-size: 13px;
        }
    }
    .content {
        .title {
            font-size: 20px;
            transition: all 0.5s ease;
            &:hover {
                color: $primary !important;
            }
        }
        .location-time {
            font-size: 14px;
        }
    }
    &:hover {
        transform: translateY(-5px);
        box-shadow: $shadow;
        border-color: $primary !important;
        background: $light !important;
    }
}

//course.html
.courses-desc {
    transition: all 0.5s ease;
    .content {
        .title {
            transition: all 0.5s ease;
            &:hover {
                color: $primary !important;
            }
        }
    }
    .collection, .preview {
        position: absolute;
        opacity: 0;
        z-index: 1;
    }
    .collection {
        top: 15px;
        left: 15px;
        i {
            -webkit-text-stroke: 2px $danger;
            -webkit-text-fill-color: transparent;
            &:active {
                -webkit-text-stroke: 0px $danger;
                -webkit-text-fill-color: $danger;
            }
        }
    }
    .preview {
        bottom: 15px;
        right: 15px;
    }
    &:hover {
        box-shadow: $shadow;
        transform: translateY(-10px);
        .collection, .preview {
            opacity: 1;
        }
    }
}

//index-portfolio.html
.personal-port {
    opacity: 0;
    transition: all 0.5s ease;
}

//index-portfolio.html
.container-filter {
    li {
        a {
            font-size: 13px;
            padding: 0px 15px; 
            margin: 5px 3px;
            font-weight: 700;
            letter-spacing: 0.8px;
            cursor: pointer;
            line-height: 34px;
            transition: all 0.5s ease;
            &.active,
            &:hover {
                color: $primary !important;
                border-color: $primary !important;
            }
        }
    }
}
.spacing{
    padding-left: 7px;
    padding-right: 7px;
}
